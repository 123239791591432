export enum PaymentType {
    naoIniciado = 0,
    credito = 1,
    debito = 2,
    dinheiro = 3,
    cashless = 4,
    meepCoin = 5,
    misto = 6,
    cheque = 7,
    pendente = 8,
    boleto = 9,
    reembolso = 10,
    cashback = 11,
    producao = 12,
    ajuste = 13,
    voucher = 14,
    delivery = 15,
    perda = 16,
    preRecharge = 20,
    pix = 18,
    PIX_OLD_WALLET_DEPRECATED = 22,
    semPagamento = 90,
    cielo = 100
}
