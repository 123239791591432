import { FC, RefObject, useState } from 'react';
import styles from './PaymentSelector.module.scss';
import { PaymentType } from '../../../../modules/consume/domain/models/PaymentType';
import { Button } from '@mui/material';
import { moneyFormater } from '../../../../services/utils/Money';
import MoneyInput from '../../../TableMap/components/partialPaymentInput/MoneyInput';
import UseDeviceStore from '../../../../modules/device/store/DeviceStore';

export interface IPaymentSelectiorProps {
    paymentType: PaymentType | undefined,
    onChangePaymentType: (paymentType: PaymentType) => void,
    onFocusInput: (inputEvent: RefObject<HTMLInputElement>) => void,
    amountToPay: number
    showCielo?: boolean
}

export const PaymentSelector: FC<IPaymentSelectiorProps> = ({
    paymentType, amountToPay, onChangePaymentType, onFocusInput, showCielo
}) => {
    const [deviceConfig] = UseDeviceStore(state => [state.deviceConfig]);

    const [moneyValue, setMoneyValue] = useState<number>();

    const handlePaymentIcon = (currentPaymentType: PaymentType) => {
        switch (currentPaymentType) {
            case 1:
                return "/assets/img/credit-card.svg"
            case 2:
                return "/assets/img/debit-card.svg"
            case 3:
                return "/assets/img/money.svg"
            case 18:
                return "/assets/img/pix.svg"
            default:
                return "";
        }//TODO: COLOCAR O ENUM
    };

    return (
        <div className={styles.paymentType}>
            <b>Forma de pagamento</b>
            <div className={styles.buttonContainer}>
                {deviceConfig?.payment.methods.map((payment) => {
                    var _paymentType = payment.type === PaymentType.PIX_OLD_WALLET_DEPRECATED ? 18 : payment.type;
                    return (payment.enabled && (

                        <Button
                            key={_paymentType}
                            size='large'
                            classes={{ outlined: styles.outlined, text: styles.text }}
                            variant={paymentType === _paymentType ? "outlined" : "text"}
                            color='primary'
                            className={styles.button}
                            onClick={() => onChangePaymentType(_paymentType)}
                        >
                            {handlePaymentIcon(_paymentType) && <img alt="Pagamento" src={handlePaymentIcon(_paymentType)} />}
                            {payment.name}
                        </Button>
                    ))
                })}
                {showCielo && <Button size='large' classes={{ outlined: styles.outlined, text: styles.text }} variant={paymentType === PaymentType.cielo ? "outlined" : "text"} color='primary' className={styles.button} onClick={() => onChangePaymentType(PaymentType.cielo)}>
                    <img width={80} alt={'Cielo'} src='/assets/img/logo-cielo.png' />
                </Button>}
                {deviceConfig?.payment.customMethods.map((payment) => payment.enabled && (
                    <Button
                        key={payment.type}
                        size='large'
                        classes={{ outlined: styles.outlined, text: styles.text }}
                        variant={paymentType === payment.type ? "outlined" : "text"}
                        color='primary'
                        className={styles.button}
                        onClick={() => onChangePaymentType(payment.type)}
                    >
                        {handlePaymentIcon(payment.type) && <img alt="Pagamento" src={handlePaymentIcon(payment.type)} />}
                        {payment.name}
                    </Button>
                ))}
            </div>
            {paymentType === PaymentType.dinheiro &&
                <div className={styles.change}>
                    <h2>
                        Calcular Troco:
                    </h2>
                    <MoneyInput sugestion onFocus={onFocusInput} defaultValue={amountToPay} label='Valor em dinheiro' onChange={setMoneyValue} />
                    {!!moneyValue && (moneyValue - amountToPay) > 0 && <div className={styles.result}>
                        <label>Troco:</label> <b>{moneyFormater(moneyValue - amountToPay)}</b>
                    </div>}
                </div>}
        </div>
    );
};
